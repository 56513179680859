.wrap-back-btn{
    display:flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: 400;
    font-family: "Brandon";
    cursor: pointer;
}

.request-type-heading{
    font-family: "Brandon";
    text-align: center;
     margin: 0
}

.rmdp-input{
    width: 100%;
    font-family: Brandon;
    padding: 8.5px 14px !important;
    margin: 0 !important;
    margin-top: 10px !important;
}

.events-wrapper{
    display: flex;
    align-items: center;
    gap: 10px;
}

.events-wrapper > span{
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 5px;
}

.enquiry-links{
    font-size: 16px;
    font-family: Brandon;
}

.enquiry-links > li::marker{
    color: #fe7647;
}

.enquiry-links > li > a{
    color: #fe7647;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 8.5px 14px !important; /* Adjust the padding value as needed */
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #bdbdbd !important;
}

.css-1v4ccyo.Mui-error .MuiOutlinedInput-notchedOutline{
    border-color: #bdbdbd !important;
}

.css-1x5jdmq{
    padding: 8.5px 14px !important; /* Adjust the padding value as needed */
  }

.selector{
    border: 1px solid #ced4d8;
    border-radius: 5px;
    padding: 5px 10px;
    margin-top: 10px;
    font-family: Brandon;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.selected-item{
    border: 1px solid #fe7648 !important;
}

  @media (max-width:768px){
    .events-wrapper{
        display: unset;
    }
  }