.verified-badge {
  color: white;
  font-family: Brandon;
  user-select: none;
  padding: 5px 25px;
  font-weight: 700;
  background-color: #3e9742;
  border-radius: 13px;
  width: fit-content;
  margin: 0 auto !important;
}

.booking-wrapper{
  padding: 60px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-family: Brandon;
  font-size: 14px;
}

.form-container{
  width: 60%;
}

@media (max-width:1100px){
  .form-container{
      width: 100%;
  }  
}

@media (max-width: 1024px){
  .booking-wrapper{
    padding: 10px;
  }
}

.login-wrapper{
  width: 60%;
}