.loader-wrapper{
    position: fixed;
    background: #000000b3;
    width: 100%;
    height: 100vh;
    top: 0%;
    right: 0;
}

.social-spinner {
    padding: 0.5rem;
    width: 1rem;
    height: 1rem;
    margin: 10px auto;
    border: 2px solid #4a4a4a;
    border-top: 3px solid currentColor;
    border-radius: 100%;
    animation: spin 1s infinite linear;
    color: #fff;
    position: relative;
    top: 45%;
    transform: translate(-50%, -50%);
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }