.verified-badge {
    color: white;
    font-family: Brandon;
    user-select: none;
    padding: 5px 25px;
    font-weight: 700;
    background-color: #3e9742;
    border-radius: 13px;
    width: fit-content;
    margin: 0 auto !important;
  }
  
  .booking-wrapper{
    padding: 60px;
  }
  
  .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-family: Brandon;
    font-size: 14px;
  }

  .dog-action-btn{
    background-color: #fe7648 !important;
     color: #fff !important
  }
  
  @media (max-width: 1024px){
    .booking-wrapper{
      padding: 10px;
    }
  }

  .hide-scrollbar{
    box-shadow: none;
  }

  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .form-verification-container{
    padding: 60px;
    background-color:#ffff
  }

  .form-registration-container{
    padding: 0px 50px
  }
  .form-registration-wrapper{
    padding: 20px 40px;
    background-color:white;
    border-radius:22px
  }
  .dog-registration-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 50px;
  }
  .dog-registration-wrapper{
    width: 100%;
    padding: 20px 40px;
    background-color: white;
    border-radius: 22px;
  }

  @media (max-width: 600px){
    .form-verification-container{
      padding: 10px;
    }
    .form-registration-container{
      padding: 0px
    }
    .form-registration-wrapper{
      padding: 20px
    }
    .dog-registration-container{
      padding: 0px 30px;
    }
    .dog-registration-wrapper{
      padding: 0px;
      margin-left: 0px !important;
      margin-top: 10px !important;
    }
  }

  @media (max-width: 1100px){
    .form-registration-container{
      padding: 0px
    }
  }