.new-booking-container{
    max-width: 85%;
    margin: auto;
    margin-top: 40px !important;
}

.booking-req-container{
    padding: 10px;
    /* font-family: Brandon; */
}

.booking-req-wrapper{
    background-color: #f4822021;
    border-radius: 10px;
    padding: 14px;
}
.action-btn {
    display: flex;
    justify-content: end;
}
.action-btn Button {
    background-color: #f48221;
    color: white;
    padding: 5px;
    text-transform: capitalize;
}

.action-btn Button:hover {
    background-color: #f48221;
    color: white;
}

.label-heading{
    margin: 0;
    font-weight: bold
}

.label-title{
    margin: 0;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.booking-info-label{
    color: #f48221;
    font-weight: bold;
    font-family: Brandon;
    margin: 0;
}

.booking-info-title{
    font-family: Brandon;
    color: black;
    margin: 0;
    font-weight: 600;
}

.custom-btn {
    background-color: #f48221 !important;
    color: white !important;
    padding: 5px !important;
    text-transform: none !important;
}

.custom-btn:hover {
    background-color: #f48221;
    color: white;
}
.pagination-data .Mui-selected{
    background-color: #f48221 !important;
}