body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}

.css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(0, 0, 0) !important;
}

textarea:disabled {
  color: rgba(0, 0, 0) !important;
  background: transparent;
}

.css-1o6z5ng.Mui-disabled{
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}
.css-1vynybe.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}

.css-1o6z5ng.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(0 0 0) !important;
}
.skiptranslate .VIpgJd-ZVi9od-ORHb-OEVmcd{
  display: none;
}

.translated-ltr body{
  position: static !important;
}