.account-info > h3{
    margin: 5px 0px;
    font-family: Brandon;
}

.account-info > p{
    margin: 0;
    font-family: Brandon;
}

.account-tabs{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 22px;
    margin: 40px 0px;
    cursor: pointer;
}
.profile-all-address{
    border: 1px solid #e6e5e5;
    border-radius: 4px;
    padding: 18px;
    margin-top: 20px;
}

.css-13cymwt-control, .css-t3ipsp-control, .react-tel-input, .form-control{
background-color: transparent !important;
}
.meeting-req-wrapper{
    background-color: #f4822021;
    border-radius: 10px;
    padding: 14px;
}

/* .meeting-req-wrapper p{
    color: black;
} */

.meeting-action-btn {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
.meeting-action-btn Button {
    background-color: white;
    color: black;
    padding: 5px;
    text-transform: none
}

.meeting-action-btn Button:hover {
    background-color: white;
    color: black;
}