.react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 50%;
    background: white;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 2px solid #fe7648;
}

.react-multi-carousel-dot--active button {
    background: #fe7648;
}

.carousel-close-button{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.react-multi-carousel-dot button{
    border-color: #cfcdcd;
}

.react-multi-carousel-dot-list{
    bottom: 4px;
}

@media (max-width: 768px){
    .react-multi-carousel-list{
        width: 85%;
        padding: 0px;
    }
}