

@font-face {
    font-family: "Montserrat";
    src: url("./Assets/Fonts/Montserrat-Italic-VariableFont_wght.ttf");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Smooch";
    src: url("./Assets/Fonts/Smooch-Regular.ttf");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "monopola";
    src: url("./Assets/Fonts/monopola_script_demo-webfont.woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Amatic";
    src: url("./Assets/Fonts/AmaticSC-Bold.ttf");
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Brandon";
    src: url("./Assets/Fonts/Brandon_Grot.woff");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Qeinsha";
    src: url("./Assets/Fonts/Qeinsha.otf");
    font-weight: normal;
    font-style: normal;
  }

  .default-btn {
    background-color: #fe7648!important;
    border: 0!important;
    box-shadow: 0 8px 15px rgba(0,0,0,.1);
    color: #fff!important;
    padding: 5px 10px!important;
    font-family: Brandon !important;
    font-weight: bold !important;
    text-transform: none !important;
    cursor: pointer;
}

.cancel-action-btn {
  background-color: #888382!important;
  border: 0!important;
  box-shadow: 0 8px 15px rgba(0,0,0,.1);
  color: #fff!important;
  padding: 5px 10px!important;
  font-family: Brandon !important;
  font-weight: bold !important;
  text-transform: none !important;
  cursor: pointer;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.css-1rg1291-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 1px !important;
}

.css-1lasg1j{
  box-shadow: none !important;
}

.select-font{
  font-family: Brandon;
}

.margin-top-10{
  margin-top: 5px;
}
#google_translate_element{
  z-index: 9999;
  position: fixed;
  right: 0;
}
.goog-te-combo{
  padding: 2px;
  font-family: 'Brandon' !important;
  font-weight: bold;
}

.goog-te-gadget{
  visibility: hidden;
  display: flex;
  flex-direction: row;
}

.goog-te-gadget > span {
  display: none;
}

.goog-te-gadget > div {
  visibility: visible;
}

.welcome-wrapper{
  width: 100%;
  height: 100%;
  background: #0000005c
}
.welcome-text{
  font-family: Qeinsha;
  color: white;
  margin: 0;
  padding-top: 100px;
  text-align: center;
  font-size: 40px;
  font-weight: bold
}

@media  (max-width: 600px){
  .welcome-wrapper{
    display: none;
  }
}